/* Root Layout */
.App {
  overflow: hidden;
}

/* Utility Classes */
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Tags */
button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

/* MUI */
.MuiTextField-root {
  background-color: #fff;
  border-radius: 5px;
}

.MuiChip-outlined {
  background-color: #fff !important;
  color: #000 !important; 
}

/* Styles for mobile screens */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1rem;
  }

  .flex-row,
  .flex-column {
    flex-direction: column;
  }
}


