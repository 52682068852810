.list {
  &-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  &-item {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #ccc;
      width: 100%;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }
}
