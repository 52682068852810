// animation for list container
@keyframes slideIn {
  0% {
    right: -400px;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -4 00px;
  }
}

.list {
  &-container {
    background-color: #fff;
    border-radius: 16px 0 0 16px;
    height: 80vh;
    max-width: 400px;
    overflow: auto;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 100px;
  }

  &-item-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 10px;

    &:hover {
      border: black solid 1px;
    }
  }

  &-item:not(:last-child) {
    margin-right: 10px;
  }
}

.visible {
  animation: slideIn 1s ease-in-out;
}
.hidden {
  animation: slideOut 1s ease-in-out;
}
